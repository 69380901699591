import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import "./styles.scss";
import { HotelPreferencesConnectorProps } from "./container";
import {
  AmenitiesSelection,
  B2BSpinner,
  ButtonWrap,
  CloseButtonIcon,
  FreeCancelFilter,
  Icon,
  IconName,
  MobilePopoverCard,
  StarRatingsSelection,
  UserPreferencesSavedBanner,
  mapAmenitiesToTopAmenities,
  mapTopAmenitiesToAmenities,
  useDeviceTypes,
  usePrevious,
} from "halifax";
import clsx from "clsx";
import {
  AmenityEnum,
  CallState,
  HotelStarRatingEnum,
  SAVED_HOTEL_PREFERENCES,
  TopAmenityEnum,
  UserHotelPreferencesPayload,
} from "redmond";
import {
  AMENITIES_HEADING_TEXT,
  AMENITIES_SECONDARY_TEXT,
  CANCELLATION_HEADING_TEXT,
  RESET_CTA_TEXT,
  SAVE_CTA_ACTIVE_TEXT,
  SAVE_CTA_TEXT,
  STAR_RATING_HEADING_TEXT,
  STAR_RATING_SECONDARY_TEXT,
  STAR_RATING_TOOLTIP_TEXT,
} from "./textConstants";
import { isEqual } from "lodash-es";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";

const TOP_AMENITIES_TO_SHOW: TopAmenityEnum[] = [
  TopAmenityEnum.Restaurant,
  TopAmenityEnum.FreeWifi,
  TopAmenityEnum.ParkingIncluded,
  TopAmenityEnum.TwentyFourSevenFrontDesk,
  TopAmenityEnum.Accessibility,
  TopAmenityEnum.PetFriendly,
  TopAmenityEnum.Pool,
  TopAmenityEnum.Gym,
];

export interface HotelPreferencesProps extends HotelPreferencesConnectorProps {}
export const HotelPreferences = (props: HotelPreferencesProps) => {
  const {
    userHotelPreferences,
    fetchUserHotelPreferences,
    isLoadingUserHotelPreferences,
    updateUserHotelPreferences,
    isUpdatingUserHotelPreferences,
    updateUserHotelPreferencesCallstate,
    hasUserSetHotelPreferences,
    resetUpdateUserHotelPreferencesCallState,
  } = props;

  const [localUserHotelPreferences, setLocalUserHotelPreferences] =
    React.useState<{
      amenities: AmenityEnum[];
      starRatings: HotelStarRatingEnum[];
      freeCancellationOnly: boolean;
    }>();
  const [mobileStarRatingInfoModalOpen, setMobileStarRatingInfoModalOpen] =
    React.useState<boolean>(false);

  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const localUserHotelPreferencesWithMappedTopAmenities: UserHotelPreferencesPayload =
    React.useMemo(() => {
      const topAmenities = mapAmenitiesToTopAmenities(
        localUserHotelPreferences?.amenities || []
      );

      return {
        starRatings: [],
        freeCancellationOnly: false,
        ...localUserHotelPreferences,
        amenities: topAmenities.map((topAmenity) => topAmenity.value),
      };
    }, [localUserHotelPreferences]);

  const localStateDiffersFromSaved = React.useMemo(
    () =>
      !isEqual(
        userHotelPreferences,
        localUserHotelPreferencesWithMappedTopAmenities
      ),
    [userHotelPreferences, localUserHotelPreferencesWithMappedTopAmenities]
  );

  const prevLocalStateDiffersFromSaved = usePrevious(
    localStateDiffersFromSaved
  );

  const isSaving =
    isUpdatingUserHotelPreferences || isLoadingUserHotelPreferences;

  const handleSave = (clear?: boolean) => {
    if (!clear && localUserHotelPreferences) {
      updateUserHotelPreferences(
        localUserHotelPreferencesWithMappedTopAmenities
      );
    } else {
      updateUserHotelPreferences({
        amenities: [],
        starRatings: [],
        freeCancellationOnly: false,
      });
    }

    trackEvent({
      eventName: SAVED_HOTEL_PREFERENCES,
      properties: addTrackingProperties(expState.trackingProperties),
    });
  };

  const handleClear = () => {
    setLocalUserHotelPreferences(undefined);

    handleSave(true);
  };

  React.useEffect(() => {
    fetchUserHotelPreferences();
    resetUpdateUserHotelPreferencesCallState();
  }, []);

  React.useEffect(() => {
    if (userHotelPreferences) {
      setLocalUserHotelPreferences({
        ...userHotelPreferences,
        amenities: mapTopAmenitiesToAmenities(userHotelPreferences.amenities),
      });
    }
  }, [userHotelPreferences]);

  React.useEffect(() => {
    if (
      updateUserHotelPreferencesCallstate === CallState.Success &&
      matchesMobile
    ) {
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    }
  }, [updateUserHotelPreferencesCallstate]);

  return (
    <Box className={clsx("hotel-preferences-root", { mobile: matchesMobile })}>
      {isLoadingUserHotelPreferences ? (
        <Box className="hotel-preferences-loading-container">
          <B2BSpinner />
        </Box>
      ) : (
        <Box className="hotel-preferences-content">
          {hasUserSetHotelPreferences && matchesMobile && (
            <ButtonWrap
              className="hotel-preferences-cta reset-hotel-prefs"
              onClick={handleClear}
              disabled={isSaving}
            >
              {RESET_CTA_TEXT}
            </ButtonWrap>
          )}
          <hr />
          {matchesMobile &&
            updateUserHotelPreferencesCallstate === CallState.Success &&
            !localStateDiffersFromSaved &&
            !prevLocalStateDiffersFromSaved && (
              <UserPreferencesSavedBanner type="hotel" />
            )}

          <Box className="hotel-preferences-config">
            <Box className="hotel-preferences-filter">
              <Box className="hotel-preferences-filter-heading-container">
                <Box className="hotel-preferences-filter-heading-icon-title">
                  <Icon
                    className="hotel-preferences-filter-heading-icon"
                    name={IconName.StarOutline}
                  />
                  {matchesMobile ? (
                    <ButtonWrap
                      onClick={() => setMobileStarRatingInfoModalOpen(true)}
                    >
                      <Box className="hotel-preferences-title-tooltip-content">
                        <Typography className="hotel-preferences-filter-heading-primary-text">
                          {STAR_RATING_HEADING_TEXT}
                        </Typography>

                        <Icon name={IconName.InfoCircle} />
                      </Box>
                    </ButtonWrap>
                  ) : (
                    <Tooltip
                      title={
                        <Typography>{STAR_RATING_TOOLTIP_TEXT}</Typography>
                      }
                      classes={{
                        popper: "star-rating-info-tooltip-popper",
                        tooltip: "star-rating-info-tooltip",
                      }}
                      id="star-rating-info-tooltip-button"
                    >
                      <Button aria-describedby="star-rating-info-tooltip-button">
                        <Box className="hotel-preferences-title-tooltip-content">
                          <Typography className="hotel-preferences-filter-heading-primary-text">
                            {STAR_RATING_HEADING_TEXT}
                          </Typography>

                          <Icon name={IconName.InfoCircle} />
                        </Box>
                      </Button>
                    </Tooltip>
                  )}
                </Box>
                <Typography className="hotel-preferences-filter-heading-secondary-text">
                  {STAR_RATING_SECONDARY_TEXT}
                </Typography>
              </Box>
              <StarRatingsSelection
                starRatings={localUserHotelPreferences?.starRatings || []}
                setStarRatings={(newStarRatings) =>
                  setLocalUserHotelPreferences((currentPrefs) => ({
                    amenities: [],
                    freeCancellationOnly: false,
                    ...(currentPrefs ? currentPrefs : {}),
                    starRatings: newStarRatings,
                  }))
                }
                labelPlacement={matchesMobile ? "start" : "end"}
              />
            </Box>

            {matchesMobile && <hr />}

            <Box className="hotel-preferences-filter">
              <Box className="hotel-preferences-filter-heading-container">
                <Box className="hotel-preferences-filter-heading-icon-title">
                  <Icon
                    name={IconName.KnifeFork}
                    className="hotel-preferences-filter-heading-icon"
                  />

                  <Typography className="hotel-preferences-filter-heading-primary-text">
                    {AMENITIES_HEADING_TEXT}
                  </Typography>
                </Box>
                <Typography className="hotel-preferences-filter-heading-secondary-text">
                  {AMENITIES_SECONDARY_TEXT}
                </Typography>
              </Box>
              <AmenitiesSelection
                amenities={localUserHotelPreferences?.amenities || []}
                setAmenities={(newAmenities) =>
                  setLocalUserHotelPreferences((currentPrefs) => ({
                    starRatings: [],
                    freeCancellationOnly: false,
                    ...(currentPrefs ? currentPrefs : {}),
                    amenities: newAmenities,
                  }))
                }
                variant="single-column"
                useTopAmenities
                topAmenitiesToShow={TOP_AMENITIES_TO_SHOW}
                showViewMore={false}
                labelPlacement={matchesMobile ? "start" : "end"}
              />
            </Box>

            {matchesMobile && <hr />}

            <Box className="hotel-preferences-filter">
              <Box className="hotel-preferences-filter-heading-container cancellation">
                <Box className="hotel-preferences-filter-heading-icon-title">
                  <Icon
                    name={IconName.NotAllowedSign}
                    className="hotel-preferences-filter-heading-icon"
                  />

                  <Typography className="hotel-preferences-filter-heading-primary-text">
                    {CANCELLATION_HEADING_TEXT}
                  </Typography>
                </Box>
              </Box>
              <FreeCancelFilter
                hasFreeCancelFilter={
                  !!localUserHotelPreferences?.freeCancellationOnly
                }
                setFreeCancelFilter={(newCancelValue) =>
                  setLocalUserHotelPreferences((currentPrefs) => ({
                    starRatings: [],
                    amenities: [],
                    ...(currentPrefs ? currentPrefs : {}),
                    freeCancellationOnly: newCancelValue,
                  }))
                }
              />
            </Box>
          </Box>
          {!localStateDiffersFromSaved &&
            hasUserSetHotelPreferences &&
            !matchesMobile && (
              <Box className="hotel-preferences-ctas">
                <ButtonWrap
                  className="hotel-preferences-cta reset-hotel-prefs"
                  onClick={handleClear}
                  disabled={isSaving}
                >
                  {RESET_CTA_TEXT}
                </ButtonWrap>
              </Box>
            )}
          {localStateDiffersFromSaved && (
            <Box className="hotel-preferences-ctas">
              <ButtonWrap
                className="hotel-preferences-cta reset-hotel-prefs"
                onClick={handleClear}
                disabled={isSaving}
              >
                {RESET_CTA_TEXT}
              </ButtonWrap>
              <ButtonWrap
                className="hotel-preferences-cta save-hotel-prefs"
                onClick={() => handleSave()}
                disabled={isSaving}
              >
                {isSaving ? SAVE_CTA_ACTIVE_TEXT : SAVE_CTA_TEXT}
              </ButtonWrap>
            </Box>
          )}
        </Box>
      )}

      {!matchesMobile &&
        updateUserHotelPreferencesCallstate === CallState.Success &&
        !localStateDiffersFromSaved &&
        !prevLocalStateDiffersFromSaved && (
          <UserPreferencesSavedBanner type="hotel" />
        )}

      <MobilePopoverCard
        className="mobile-star-ratings-info-popover"
        open={mobileStarRatingInfoModalOpen}
        onClose={() => setMobileStarRatingInfoModalOpen(false)}
        centered={true}
        topRightButton={
          <CloseButtonIcon
            onClick={() => setMobileStarRatingInfoModalOpen(false)}
          />
        }
        headerElement={
          <Typography
            variant="h2"
            className="mobile-star-ratings-info-popover-header-text"
          >
            {STAR_RATING_HEADING_TEXT}
          </Typography>
        }
      >
        <div className="mobile-star-ratings-info-popover-content">
          {STAR_RATING_TOOLTIP_TEXT}
        </div>
      </MobilePopoverCard>
    </Box>
  );
};
